import React from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import PageVisibility from 'react-page-visibility';
import _ from 'lodash';
import retryOperation from './retry.js';
import Symbols from './Symbols.js';

export default function Alerts(props) {
    const apiURLPrefix = process.env.REACT_APP_API_PREFIX || '/api'
    // const classes = useStyles();
    const [alerts, setAlerts] = React.useState([]);
    const {liveData, positionData, updatePosition, authorized, setStatusMessage, 
      name, riskAmount, riskPercentage, from, to, wsHandler, slackWebhookUrl} = props;
    const {selectedAccount} = props;

    const fetchAlerts = () => {
      const toParam = moment().format();
      retryOperation(() => fetch(`${apiURLPrefix}/alerts/?name=${name ? name : ''}&to=${toParam}`)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw JSON.parse(response).error;
        }), 1000, 5)
        .then((alerts) => setAlerts(alerts));
    }

    React.useEffect(()=>{
        if (authorized) {
          fetchAlerts();
        }
    }, [selectedAccount, from, to, authorized]);

    const visibilityHandler = isVisible => {
        if (isVisible) {
            fetchAlerts();
        }
    }


    const alertData = _.groupBy(alerts, a => a.symbol);
    return <PageVisibility onChange={visibilityHandler}>
      <Symbols alertData={alertData} wsHandler={wsHandler} setStatusMessage={setStatusMessage} 
        selectedAccount={selectedAccount} 
        updatePosition={updatePosition} 
        riskAmount={riskAmount}
        riskPercentage={riskPercentage}
        positionData={positionData}
        liveData={liveData}
        slackWebhookUrl={slackWebhookUrl}
      />
    </PageVisibility>
}

Alerts.propTypes = {
    selectedAccount: PropTypes.string.isRequired,
    setStatusMessage: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    riskAmount: PropTypes.number.isRequired,
    riskPercentage: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    authorized: PropTypes.bool.isRequired,
    liveData: PropTypes.bool.isRequired,
    updatePosition: PropTypes.object.isRequired,
    wsHandler: PropTypes.object.isRequired,
    positionData: PropTypes.object.isRequired,
    slackWebhookUrl: PropTypes.string.isRequired,
}