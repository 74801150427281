import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';

function TradingViewWidget(props) {
  const container = useRef();
  const { symbol } = props;
  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "height":"400",
          "symbol": "FX:${symbol}",
          "interval": "5",
          "timezone": "America/Vancouver",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "details": false,
          "backgroundColor": "rgba(0, 0, 0, 1)",
          "hide_legend":true,
          "allow_symbol_change": false,
          "save_image": false,
          "calendar": false,
          "hide_volume": true,
          "support_host": "https://www.tradingview.com"
        }`;
      container.current.appendChild(script);
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noreferrer noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
    </div>
  );
}

TradingViewWidget.propTypes = {
    symbol: PropTypes.string.isRequired,
};
export default memo(TradingViewWidget);
