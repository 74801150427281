import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

export default function ProfitLoss(props) {
    const val = parseFloat(props.value);
    let style = {};
    if (val < 0) {
        style = {color: "#f00", "font-weight": "bold"}
    } else if (val > 0) {
        style = {color: "#0a0", "font-weight": "bold"}
    }

    return (
        <NumericFormat style={style} value={props.value === undefined ? null : props.value}
            displayType={'text'}
            allowNegative={true} thousandSeparator={true} 
            decimalScale={2} fixedDecimalScale={true}/>
    );
}

ProfitLoss.propTypes = {
    value: PropTypes.number.isRequired,
}