import React from 'react';
import TradingViewWidget from './TradingViewWidget';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router';

export default function SymbolChart() {
    const { symbol } = useParams();

    return (
        <Grid>
            <Grid container alignItems="flex-start" justifyContent="center" size={{ xs: 12, md: 2 }}>
                <Box>{symbol}</Box>
            </Grid>
            <Grid container size={{ xs: 12, md: 10 }}>
                <TradingViewWidget symbol={symbol} />
            </Grid>
        </Grid>
    );
}