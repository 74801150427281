import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
 import { Switch, Chip, Autocomplete, TextField, Grid, Button, Typography } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import { ArrowDropDown, ArrowDropUp} from '@mui/icons-material'
import retryOperation from './retry.js';
import moment from 'moment';
import _ from 'lodash'
import { NumericFormat } from 'react-number-format';
import { useSearchParams, NavLink } from 'react-router-dom';
import TradingViewWidget from './TradingViewWidget';

const PREFIX = 'Symbols';

const classes = {
    symbolBox: `${PREFIX}-symbolBox`,
    button: `${PREFIX}-button`,
    buy: `${PREFIX}-buy`,
    sell: `${PREFIX}-sell`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.symbolBox}`]: {
        // width: "100%",
        border: "solid 1px #555",
        borderRadius: 3,
        padding: theme.spacing(2),
    },

    [`& .${classes.button}`]: {
    },

    [`& .${classes.buy}`]: {
        // color: theme.palette.text.primary,
        backgroundColor: '#373'
    },

    [`& .${classes.sell}`]: {
        color: theme.palette.text.primary,
        backgroundColor: '#933'
    }
}));

export default function Symbols(props) {
    const apiURLPrefix = process.env.REACT_APP_API_PREFIX || '/api'

    const [ filter,  setFilter ] = React.useState()
    const [ sortMode,  setSortMode ] = React.useState('change')
    const {liveData, updatePosition, setStatusMessage, selectedAccount, wsHandler, 
        alertData, riskAmount, riskPercentage,  slackWebhookUrl} = props;
    const [searchParams] = useSearchParams();
    const symbol = searchParams ? searchParams.get('symbol') : null;

    const sortModeHandler = (event) => {
        setSortMode(event.target.checked ? 'alert' : 'change');
        dispatch({type: 'sort'});
    }

    const _executeOrder = (symbol, orderRequest) => {
      orderRequest.volume = orderRequest.adjustedVolume
      const [ provider ] = selectedAccount.split(':');
      fetch(`${apiURLPrefix}/${provider}/orders/execute/${symbol}`, {
        method: 'POST',
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(orderRequest)
      }).then((response) => {
          console.log(response)
          if (response.status === 200) {
              // success
              setStatusMessage(`${symbol} ${orderRequest.direction} position created.`);
              updatePosition();
          } else {
              throw response.statusText;
          }
      }).catch((error) => {
          // failure
          setStatusMessage(`${symbol} Failed to create ${orderRequest.direction} order: ${error}`, 'error');
      });
    }

    const fetchSymbols = () => {
        if (selectedAccount) {
            const [ provider ] = selectedAccount.split(':');
            retryOperation(() => fetch(`${apiURLPrefix}/${provider}/symbols`)
                .then(response => {
                    if (response.ok) {
                        return response;
                    }
                    throw JSON.parse(response.body).error
                }), 1000, 5)
                .then((response) => response.json())
                .then((data) => {
                    dispatch({type: 'fetch', data})
                })
                .then(() => dispatch({type: 'sort'}))
        }
    }

    const sortSymbolChange = (symbols) => {
        var sortedList = symbols.list.sort((a, b) => {
            if (a.category === b.category) {
                const c1 = getChange(a.dailyCandle)
                const c2 = getChange(b.dailyCandle)
                const r1 = Math.abs(c1.change) - 10*Math.floor((c1.lag-10)/Math.abs(c1.lag-10))
                const r2 = Math.abs(c2.change) - 10*Math.floor((c2.lag-10)/Math.abs(c2.lag-10))
                return r2 - r1
            } else {
                return a.category.localeCompare(b.category);
            }
        });
        return {...symbols, list: sortedList}
    }

    const sortSymbols = (symbols) => {
        var sortedList = symbols.list.sort((a, b) => {
            const bLength = alertData[b.name] ? alertData[b.name].length : 0
            const aLength = alertData[a.name] ? alertData[a.name].length : 0
            const bLastFire = bLength > 0 ? Math.floor(moment(alertData[b.name][bLength-1].lastFire).unix()/3600) : 0;
            const aLastFire = aLength > 0 ? Math.floor(moment(alertData[a.name][aLength-1].lastFire).unix()/3600) : 0;
            if (bLastFire === aLastFire) {
                return bLength - aLength;
            }
            return bLastFire - aLastFire;

        })
        return {...symbols, list: sortedList}
    }

    const selectSymbols = (category) => {
        if (symbols && symbols.list) {
            try {
                return symbols.list.filter(s => s.category === category && (symbol ? s.name === symbol : true))
            } catch(e) {
                console.log(category)
                console.log(symbols.list)
                console.log(e)
            }
        } 
    }

    const executeOrder = (symbol, direction, atr, quoteRate) => {
       _executeOrder(symbol, {
            symbol: symbol,
            direction: direction,
            orderType: 'MARKET',
            takeProfitDistance: 0,
            atr: atr,
            risk: riskAmount,
            riskPercentage: riskPercentage,
            quoteRate: quoteRate,
            label: 'manual'
        });
    }

    const spotHandler = (event) => {
        dispatch({type: 'update', data: JSON.parse(event.data)})
    }

    const sendSlackMessage = (message) => {
        fetch(slackWebhookUrl, {
            method: 'POST',
            body: JSON.stringify({
                text: message,
            })
        }).then(response => {
            console.log(response)
        });
    }

    React.useEffect(() => {
        if (wsHandler) {
            wsHandler.addHandler(spotHandler);
        }
        sendSlackMessage('Notification initialized')
    }, [wsHandler]);

    React.useEffect(() => {
        dispatch({type: 'sort'})
    }, [alertData]);

    React.useEffect(() => {
        fetchSymbols();
        // if (wsHandler) {
        //     wsHandler.addHandler(spotHandler);
        // }
        // const interval = setInterval(() => {
        //     if (selectedAccount) {
        //         fetchSymbols();
        //     }
        // }, 1000);
        // return () => clearInterval(interval);
    }, [selectedAccount, wsHandler]);

    const getCurrencyMap = (l) => {
        var now = moment();
        var currencyMap = {};
        if (!l) {
            return currencyMap;
        }
        var pushList = [];
        switch(sortMode) {
            case 'alert':
                l.filter(sym => {
                    const alertLength = alertData[sym.name] ? alertData[sym.name].length : 0;
                    const lastAlertTime = alertLength > 0 ? moment(alertData[sym.name][alertLength - 1].lastFire) : moment(0);
                    return now - lastAlertTime < 3900000
                }).forEach(sym => {
                    const alertLength = alertData[sym.name] ? alertData[sym.name].length : 0;
                    const orderRequest = alertLength > 0 ? alertData[sym.name][alertLength - 1].orderRequest : null;
                    if (orderRequest) {
                        const base = sym.name.slice(0, 3);
                        const cross = sym.name.slice(3);
                        const baseCounter = currencyMap[base] || [0, 0];
                        const crossCounter = currencyMap[cross] || [0, 0];
                        if (orderRequest.direction === 'LONG') {
                            baseCounter[0] += 1;
                            crossCounter[1] += 1;
                        } else {
                            baseCounter[1] += 1;
                            crossCounter[0] += 1;
                        }
                        currencyMap[base] = baseCounter;
                        currencyMap[cross] = crossCounter;
                    }
                })
                break;
            case 'change':
                pushList = l.filter(sym => {
                    const {lag} = getChange(sym.dailyCandle)
                    return lag < 10;
                });
                pushList.forEach(sym => {
                    const {change} = getChange(sym.dailyCandle)
                    const base = sym.name.slice(0, 3);
                    const cross = sym.name.slice(3);
                    const baseCounter = currencyMap[base] || [0, 0];
                    const crossCounter = currencyMap[cross] || [0, 0];
                    if (change > 0) {
                        baseCounter[0] += 1;
                        crossCounter[1] += 1;
                    } else {
                        baseCounter[1] += 1;
                        crossCounter[0] += 1;
                    }
                    currencyMap[base] = baseCounter;
                    currencyMap[cross] = crossCounter;
                })
                break;
        } 
        return currencyMap;

    }
    const renderGrouping = (l) => {
        const currencyMap = getCurrencyMap(l);
        return !singleSymbolView && <StyledGrid container item xs={12} spacing={1}>
            {_.toPairs(currencyMap).sort((a, b) => {return Math.abs(b[1][0] - b[1][1]) - Math.abs(a[1][0] - a[1][1])}).map((d) => {
                const currency = d[0];
                const counts = d[1];
                return (
                    <Grid key={currency}>
                        {counts[0] > 0 ? <Chip onClick={() => setFilter(filter ? null : currency)} style={{color: '#0f0', borderColor: '#0f0'}} 
                            variant={filter === currency ? "filled" : "outlined"} 
                            icon={<ArrowDropUp style={{color: '#0f0'}}/>} 
                            label={`${currency} ${counts[0]}`} /> : null}
                        {counts[1] > 0 ? <Chip onClick={() => setFilter(filter ? null : currency)} style={{color: '#f00', borderColor: '#f00'}} 
                            variant={filter === currency ? "filled" : "outlined"} 
                            icon={<ArrowDropDown style={{color: '#f00'}}/>} 
                            label={`${currency} ${counts[1]}`} />: null}
                    </Grid>
                );
            })}
        </StyledGrid>;
    }
    
    const getChange = (candle) => {
        const {high, close, low, previousClose} = candle || {high: 0, close: -1, low: 0, previousClose: -1};
        const change = ((close-previousClose)/previousClose*100).toFixed(2);
        const highChange = ((high-previousClose)/previousClose*100).toFixed(2);
        const lowChange = ((low-previousClose)/previousClose*100).toFixed(2);
        const coefficient = change / Math.abs(change > 0 ? change-highChange : change-lowChange) 
        const lag = Math.abs(change > 0 ? ((change-highChange)/highChange)*100 : ((change-lowChange)/lowChange)*100) 
        return {change, highChange, lowChange, coefficient, lag, lagging: lag > 10, close}
    }

    const renderSymbols = (symbols, f) => {

        var list = symbols && symbols.filter(
                sym => !f|| f.length === 0 || 
                    sym.name.toLowerCase().includes(filter.toLowerCase()))
        if (!list) {
            return
        }
        const renderList = (l) => {
            return (
                <StyledGrid container item xs={12} id="chart">
                {l.map(sym => {
                    const alertLength = alertData[sym.name] ? alertData[sym.name].length : 0;
                    const lastAlert = alertLength > 0 ? alertData[sym.name][alertLength - 1] : null;
                    if (lastAlert) {
                        const vol = parseFloat(lastAlert.orderRequest.volume)*riskAmount/lastAlert.orderRequest.risk/100
                        const stepVolume = lastAlert.orderRequest.stepVolume ? lastAlert.orderRequest.stepVolume/100.0 : vol > 1000 ? 1000.0 : 0.1
                        lastAlert.orderRequest.adjustedVolume = Math.floor(vol/stepVolume)*stepVolume * 100
                    }
                    const defaultVolume = riskAmount*sym.quoteRate/(sym.atr*1.5*1000);
                    const lastAlertTime = alertLength > 0 ? moment(alertData[sym.name][alertLength - 1].lastFire) : moment(0);
                    const {lag, change, highChange, lowChange, close} = getChange(sym.dailyCandle)
                    var highlight = false;
                    switch (sortMode) {
                        case 'alert':
                            highlight = moment() - lastAlertTime < 3900000
                            break;
                        case 'change':
                            highlight = lag < 10
                            break;
                    }
                    const color = parseFloat(change) === 0 ? '#aaa' : (parseFloat(change) > 0 ? '#5d5' : '#d55')
                    const icon = (!sym.changeDir || sym.changeDir == 0) ? <RemoveIcon /> : sym.changeDir > 0 ? <ArrowDropUp style={{color: '#0f0'}}/> : <ArrowDropDown style={{color: '#f00'}}/>
                    const groupByName = alertData[sym.name] ? _.groupBy(alertData[sym.name], alert => alert.name) : {};
                    const singleSymbolView = symbol && symbols && symbols.some(s => s.name === symbol);
                    const symbolGridWidth = {xs: 12, md: singleSymbolView ? 12 : 4};
                    return (
                        <>
                            <Grid key={sym.name} className={classes.symbolBox} container alignContent="flex-start" justifyContent="space-between"
                                align="center" alignItems="center" style={{borderColor: highlight ? '#aaa' : null}}
                                size={{ xs: symbolGridWidth.xs, md: symbolGridWidth.md }}>
                            {
                            <Grid container alignItems="center" justifyContent="space-between" size={12}>
                                <Grid justifyContent="space-between" container alignItems="center" size={{ md: 6, xs: 12 }}>
                                    <NavLink style={() => {
                                        return {
                                            color: '#aaa'
                                        }
                                    }} to={`/?symbol=${sym.name}`}> 
                                        <Typography variant="body2">{sym.name}</Typography>
                                    </NavLink>
                            {liveData && <Grid><Chip icon={icon} size="small" variant="outlined" style={{color: color, borderColor: color}} 
                                label={`${close > 0 ? change +'%': '--'} 
                                    ${change > 0 ? 'H: '+(close > 0 ? highChange +'%': '--') : 'L: '+(close > 0 ? lowChange + '%': '--')}`}/></Grid>}
                            {liveData && <Grid>
                                    <Typography variant="caption" color="#aa0">
                                        {sym.atr && sym.pipPosition ? parseFloat(sym.atr*10**sym.pipPosition).toFixed(1) : '--'} / {sym.spread && sym.pipPosition ? parseFloat(sym.spread*10**sym.pipPosition).toFixed(1) : '--'}
                                    </Typography>
                                    </Grid>}
                            {liveData ? <Grid>
                                    <Typography variant="caption" color="#0aa">  
                                        {close > 0 ? <NumericFormat displayType='text' fixedDecimalScale decimalScale={sym.pipPosition+1} value={close} /> : '--'}
                                    </Typography>
                                </Grid> : null}
                                </Grid>
                                <Grid
                                    sx={{marginTop: {xs: 3, md: 0}}}
                                    container
                                    justifyContent={{md: "flex-end", xs: "center"}}
                                    size={{
                                        md: 6,
                                        xs: 12
                                    }}>
                                    {lastAlert && 
                                        <Button sx={{mr: 1}} color={lastAlert.orderRequest.direction === "LONG" ? "buy" : "sell"} variant="contained" 
                                            onClick={()=>{_executeOrder(sym.name, lastAlert.orderRequest)}}>
                                                <NumericFormat displayType='text' fixedDecimalScale decimalScale={0} value={lastAlert.orderRequest.adjustedVolume/100000} />K
                                        </Button>
                                    }
                                    <Button sx={{mr: 1}} color="buy" variant="outlined"
                                        onClick={()=>{executeOrder(sym.name, 'LONG', sym.atr, sym.quoteRate)}}>
                                        <NumericFormat displayType='text' fixedDecimalScale decimalScale={0} value={defaultVolume} />K
                                    </Button>
                                    <Button sx={{mr: 1}} color="sell" variant="outlined"
                                        onClick={()=>{executeOrder(sym.name, 'SHORT', sym.atr, sym.quoteRate)}}>
                                        <NumericFormat displayType='text' fixedDecimalScale decimalScale={0} value={defaultVolume} />K
                                    </Button>
                                </Grid> 
                            </Grid>}
                            <Grid container justifyContent="space-around" size={12}>
                                    
                                { !singleSymbolView && 
                                <Grid container justifyContent="space-between">
                                    {Object.keys(groupByName).map(alertName => {
                                        return (
                                            <Grid id={`${alertName}-foobar`} container spacing={1} key={alertName}>
                                                <Grid container align="center" size={12}>
                                                    <Typography variant="caption" >{alertName.replace("Strategy", "")}</Typography>
                                                </Grid>
                                            {groupByName[alertName].map(a => {
                                                if (a.orderRequest) {
                                                    a.orderRequest.label = a.name;
                                                    const highCount = a.events.filter(e => e.Importance === 'high').length
                                                    const mediumCount = a.events.filter(e => e.Importance === 'medium').length
                                                    // const chipProps = {icon: a.orderRequest.direction === 'LONG' ? <ArrowDropUp style={{color: '#0f0'}} /> : <ArrowDropDown style={{color: '#f00'}} />, variant: 'outlined'}
                                                    var chipProps = {sx:{color: 'black', backgroundColor: a.orderRequest.direction === 'LONG' ? '#0f0' : '#f00'}, variant: 'filled'}
                                                    if (highCount > 0) {
                                                        chipProps = {color: 'error', variant: 'outlined'}
                                                    } else
                                                    if (mediumCount > 0) {
                                                        chipProps = {color: 'warning', variant: 'outlined'}
                                                    }
                                                    const alertDate = moment(a.lastFire);
                                                    return (
                                                        <Grid key={`${a.symbol}-${a.lastFire}`} className={classes.chip}>
                                                        <Chip
                                                            label={`${alertDate.format("HH")} ${a.change ? (a.change * 100).toFixed(2) + '%' : ''}`}
                                                            {...chipProps} /></Grid>
                                                    );
                                                } else {
                                                    return (
                                                        <Grid><Chip key={a.symbol} 
                                                            className={classes.chip}
                                                            label={`${a.symbol} ${a.name}`}
                                                            variant='outlined' /></Grid>
                                                    );
                                                }
                                            })}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                            {singleSymbolView && <Grid container size={12}>
                                <TradingViewWidget symbol={symbol} />
                            </Grid>}
                        </>
                    );
                })}
                </StyledGrid>
            );
            
        }
        return (
            <Grid container alignItems="flex-start" spacing={1} className={classes.symbolBox} style={{borderStyle: 'none none none', borderWidth: '1px'}} size={12}>
                {renderGrouping(selectSymbols("FOREX"))}
                {renderList(list)}
            </Grid>
        );
    }

    const collateData = arr => {
        return _.toPairs(_.groupBy(arr, d => d.name)).map(p => p[1].pop())
    }

    const reducer = (state, action) => {
        const {type, data} = action;
        let newState = state; 
        // const dataMap = {};
        // var [forex, index, metals, crypto] = []
        const pushData = state.pushData || {};
        const currencyMap = getCurrencyMap(newState.list);
        switch(type) {
            case 'sort':
                return (sortMode == 'alert' ?
                    sortSymbols(state) :
                    sortSymbolChange(state));
            case 'fetch':
                data.forEach(sym => {
                    state.dataMap.set(sym.name, sym);
                })

                return {list: data, dataMap: state.dataMap};

            case 'update':
                switch (data.type) {
                    case 'SpotSymbolData':
                        collateData(data.spotData).forEach(data => {
                            var s = state.dataMap.get(data.name)
                            if (s) {
                                var changeData = {}
                                if (data.candle && s.candle && data.candle.close !== s.candle.close) {
                                    changeData = {changeDir: data.candle.close > s.candle.close ? 1 : -1 }
                                }
                                var newData = {...s, ...data, ...changeData,
                                    candle: { ...(data.candle || s.candle), previousClose: s.previousClose },
                                    dailyCandle: { ...(data.dailyCandle || s.dailyCandle), previousClose: s.previousClose },
                                }
                                state.dataMap.set(data.name, newData);
                                newState = {
                                    dataMap: newState.dataMap, 
                                    list: newState.list.map(sym => {
                                        if (sym.name === data.name) {
                                            return newData;
                                        }
                                        return sym;
                                    })
                                }
                            }
                        });
                        break;
                    case 'TickData':
                        collateData(data.tickData).forEach(data => {
                            var s = state.dataMap.get(data.name)
                            if (s) {
                                var newData = {...s, spread: data.spread || s.spread, }
                                state.dataMap.set(data.name, newData);
                                newState = {
                                    dataMap: newState.dataMap,
                                    list: newState.list.map(sym => {
                                        if (sym.name === data.name) {
                                            return newData;
                                        }
                                        return sym;
                                    })
                                }
                            }

                        })
                        break;
                }
                _.toPairs(currencyMap).filter(c => Math.max(...c[1]) > 5).forEach(c => {
                    const currency = c[0];
                    const counts = c[1];
                    const maxCount = Math.max(...counts)
                    if (!pushData[currency] || pushData[currency] != maxCount) {
                        sendSlackMessage(`${currency}: ${maxCount} - Total push: ${Object.keys(currencyMap).length}`);
                        pushData[currency] = maxCount;
                    }
                });
                return {...newState, pushData};
            }
    }

    const [symbols, dispatch] = React.useReducer(reducer, {list:[], dataMap: new Map()})
    const singleSymbolView = symbol && symbols && symbols.list && symbols.list.some(s => s.name === symbol);

    return symbols && <Grid container>
        <Grid container sx={{marginBottom:5}} alignItems="center" spacing={3} justifyContent="flex-start" size={12}> {!singleSymbolView && (<>
            <Grid size={{ xs: 12, sm: 8 }}>
            <Autocomplete 
                options={symbols.list.map(sym => sym.name)}
                defaultValue={filter}
                onInputChange={(event, newInputValue) => {
                    setFilter(newInputValue);
                }}
                freeSolo="true"
                renderOption={()=>{}}
                renderInput={(params) => <TextField {...params} label="Search" />}
            />
            </Grid>
            <Grid>
                <Button variant="outlined" onClick={() => dispatch({type: 'sort'})}>Sort</Button>
            </Grid>
            <Grid>
                <Typography variant="caption">Change</Typography>
                {<Switch checked={sortMode == 'alert'} onChange={sortModeHandler} />}
                <Typography variant="caption">Signal</Typography>
            </Grid>
            </>)}
        </Grid>
        {renderSymbols(selectSymbols("FOREX"), filter)}
        {/* {renderSymbols(selectSymbols("INDEX"), filter)}
        {renderSymbols(selectSymbols("METALS"), filter)}
        {renderSymbols(selectSymbols("CRYPTO"), filter)} */}
    </Grid>;
}

Symbols.propTypes = {
    selectedAccount: PropTypes.string.isRequired,
    setStatusMessage: PropTypes.object.isRequired,
    wsHandler: PropTypes.object.isRequired,
    updatePosition: PropTypes.object.isRequired,
    alertData: PropTypes.object.isRequired,
    riskAmount: PropTypes.number.isRequired,
    riskPercentage: PropTypes.number.isRequired,
    liveData: PropTypes.bool.isRequired,
    slackWebhookUrl: PropTypes.string.isRequired,
}
