import React from 'react';
import { styled } from '@mui/material/styles';
import TradeList from './TradeList';
import TradeSummary from './TradeSummary';
import DailyWinLossChart from './DailyWinLossChart';
import DailyProfitLossChart from './DailyProfitLossChart';
import PerformanceBySymbol from './PerformanceBySymbol';
// import { accountList } from './AccountList';
import Positions from './Positions';
import Version from './Version';
import Alerts from './Alerts';
import { Accordion, AccordionSummary, AccordionDetails,
    CircularProgress, CssBaseline, Grid, InputLabel, Box, Button, Backdrop,
    FormControl, Switch, AppBar, Toolbar, IconButton,
    Snackbar, Alert, Select, Slider, Dialog,
    Typography
} from '@mui/material'
import { Refresh, AddCircle, RemoveCircle } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import _ from 'lodash'
import WebSocketHandler from './WebSocketHandler';
import retryOperation from './retry.js';
import PageVisibility from 'react-page-visibility';
import { useWakeLock } from 'react-screen-wake-lock';
import { useSearchParams, NavLink } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const PREFIX = 'Dashboard';

const classes = {
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    container: `${PREFIX}-container`,
    textField: `${PREFIX}-textField`,
    backdrop: `${PREFIX}-backdrop`,
    tile: `${PREFIX}-tile`
};

const StyledPageVisibility = styled(PageVisibility)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
      margin: theme.spacing(1),
      minWidth: 120,
    },

    [`& .${classes.selectEmpty}`]: {
      marginTop: theme.spacing(2),
    },

    [`& .${classes.container}`]: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    [`& .${classes.textField}`]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },

    [`& .${classes.backdrop}`]: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    [`& .${classes.tile}`]: {
        padding: 2
    }
}));

const dayjs = require('dayjs');

export default function Dashboard() {
    const apiURLPrefix = process.env.REACT_APP_API_PREFIX || '/api'
    const midaURLPrefix = '/mida';

    const [initialized, setInitialized] = React.useState(false);
    const [accountList, setAccountList] = React.useState([]);
    const [switchChecked, setSwitchChecked] = React.useState(false);
    const [risk, setRisk] = React.useState(0.5);
    const [status, setStatus ] = React.useState({});
    const setStatusMessage = (message, severity) => {
        setStatus({message: message, severity: severity ? severity : 'success'})
    }
    const [selectedAccount, setAccount] = React.useState();
    const [from, setFrom] = React.useState();
    const [to, setTo] = React.useState();
    const [symbolFilter, setSymbolFilter] = React.useState();
    const [tradeSummary, setTradeSummary] = React.useState();
    // const [positions, setPositions] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [authorized, setAuthorized] = React.useState(false);
    const [webSocketHandler, setWebSocketHandler] = React.useState();
    const [tradingConfiguration, setTradingConfiguration] = React.useState({});
    const [positionChangeTime, setPositionChangeTime] = React.useState();
    const [positionData, setPositionData] = React.useState([]);
    const [liveData, setLive] = React.useState(false);
    const { isSupported, released, request, release } = useWakeLock();
    const [searchParams] = useSearchParams();
    const symbol = searchParams.get('symbol');

    const updatePosition = () => {
        setPositionChangeTime(dayjs())
    }

    const switchChangeHandler = (event) => {

        const [ provider ] = selectedAccount.split(':');
        fetch(`${apiURLPrefix}/${provider}/admin/engine`, {
            method: 'PUT',
            body: JSON.stringify(event.target.checked),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
        .then(data => {
            setSwitchChecked(data);
        }).catch(error => {
            setSwitchChecked(!event.target.checked);
            console.log(error);
        })
    }
    const onChangeHandler = (event) => {
        switch(event.target.id) {
            case "accountSelector": 
                setAccount(event.target.value);
                break;
            case "from":
                setFrom(dayjs(event.target.value));
                break;
            case "to":
                setTo(dayjs(event.target.value));
                break;
        }
    };
    const visibilityHandler = isVisible => {
        if (webSocketHandler) {
            if (isVisible) {
                webSocketHandler.connect();
                if (selectedAccount) {
                    const [ provider ] = selectedAccount.split(':');
                    fetch(`${midaURLPrefix}/admin/account/${provider}`);
                }
            }
            else {
                if (selectedAccount) {
                    const [ provider ] = selectedAccount.split(':');
                    fetch(`${midaURLPrefix}/admin/account/${provider}`, {
                        method: 'DELETE'
                    });
                }
                webSocketHandler.close();
            }
        }
    }
    React.useEffect(()=>{
        const d = dayjs();
        setFrom(d);
        setTo(d);
        setLoading(true);
        retryOperation(() => fetch(`${apiURLPrefix}/admin/trader/configuration`).then(response => {
            if (response.status === 401 || response.ok) {
                return response.json();
            }
            throw response.statusText
        }), 1000, 10)
        .then(response => {
            setInitialized(true);
            if (response.status === 401) {
                setAuthorized(false)
                throw response;
            }
            setAuthorized(true);
            return response;
        })
        .then(configuration => {
            var arr = configuration.accountList.map(account => {
                return {
                    id: account.id,
                    provider: account.ctraderId,
                    accountId: account.ctraderId,
                    alias: account.accountNumber,
                    shortcut: true,
                    tradingSwitchEnabled: true
                }
            }).sort((a, b) => a.id - b.id);
            setTradingConfiguration(configuration);
            setAccountList(arr)
            setAccount(`${arr[0].provider}:${arr[0].accountId}`);
            return arr[0].provider;
        })
        .then(provider => fetchData(provider))
        .then(() => setLoading(false))
        .catch(error => {
            setLoading(false);
            let msg = error;
            if (typeof error === "object") {
                msg = error.statusText
            }
            setStatusMessage(msg, "error");
        });
        return () => {
            if (webSocketHandler) {
                webSocketHandler.close();
            }
        }
    }, [initialized]);
    React.useEffect(()=>{
        // const interval = setInterval(() => {
            if (selectedAccount) {
                const [ provider, ] = selectedAccount.split(':');
                const handler = new WebSocketHandler(tradingConfiguration.wsUrl, provider)
                // handler.addHandler(positionEventHandler)
                setWebSocketHandler(handler)
            }
        // }, 5000);
        refresh();
        if (selectedAccount) {
            const [ provider ] = selectedAccount.split(':');
            retryOperation(() => fetch(`${apiURLPrefix}/${provider}/admin/configuration`)
                .then(response=>response.json())
                .then(data=>{
                    console.log(data);
                    setSwitchChecked(data.active);
                    setRisk(data.riskPercentage);
                    setLive(true);
                }), 1000, 5)
                .then(() => retryOperation(() => fetch(`${midaURLPrefix}/admin/account/${provider}`), 1000, 5))
        }
        // return () => clearInterval(interval);
    }, [selectedAccount, from, to]);

    const updateRisk = (event, value) => {
        if (selectedAccount) {
            const [ provider ] = selectedAccount.split(':');
            fetch(
                `${apiURLPrefix}/${provider}/admin/risk`,
                {
                    method: 'PUT',
                    body: JSON.stringify(value),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(response=>response.json())
                .then(data=>{
                    console.log(data);
                    setSwitchChecked(data.active);
                    setRisk(data.riskPercentage);
                }).catch((error) => {
                    // failure
                    setStatusMessage(`Failed to update risk%: ${error}`, 'error');
                })
        }
    }

    // const positionEventHandler = (event) => {
    //     if (event.type === 'PositionList') {
    //         setPositions(event.positions);
    //     }
    // }

    const fetchData= (provider) => {
        const tradesPromise = fetch(`${apiURLPrefix}/${provider}/trades?from=${dayjs(from).format('YYYY-MM-DD')}T00:00:00&to=${dayjs(to).format('YYYY-MM-DD')}T23:59:59`).then(data => {
                return data.json()
            }).then(summary => {
                setTradeSummary(summary);
            });
        return Promise.resolve(tradesPromise);
    }

    const shutdown = () => {
        fetch(`${apiURLPrefix}/admin/shutdown`, {
            method: 'POST'
        }).then(() => {
            setStatusMessage("Shutdown initiated")
        })
    }

    const refresh = () => {
        if (selectedAccount) {
            const [ provider, account ] = selectedAccount.split(':');
            setLoading(true)
            retryOperation(() => fetchData(provider, account), 1000, 5)
                .then(() => setLoading(false));
        }
    }

    // const switchEnabled = () => {
    //     if (selectedAccount) {
    //         const [ provider ] = selectedAccount.split(':');
    //         return _.find(accountList, a => a.provider === provider && a.tradingSwitchEnabled)
    //     }
    // }

    const removeAccount = () => {
        if (selectedAccount) {
            if (accountList.length == 1) {
                setStatusMessage('Cannot remove last account')
                return
            }
            const [ provider ] = selectedAccount.split(':');
            fetch(`${apiURLPrefix}/admin/trader/remove/${provider}`, {
                method: 'DELETE'
            }).then((response) => {
                if (response.status === 200) {
                    setStatusMessage('Account deleted');
                    const newList = accountList.filter(a => {
                        return a.provider != provider
                    })
                    setAccountList(newList);
                    setAccount(`${newList[0].provider}:${newList[0].accountId}`);
                }
            })
        }
    }

    return (
        <StyledPageVisibility onChange={visibilityHandler}>
            <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
            <Backdrop className={classes.backdrop} open={!authorized && loading}><CircularProgress size={60} thickness={5} indeterminate="true" /></Backdrop>
            <Dialog fullscreen open={initialized && !authorized && !loading}><Button variant="contained" href={`${apiURLPrefix}/auth/trader/login`}>Click to Login</Button></Dialog>
            <AppBar position="fixed" style={{bottom: 0, top: 'auto'}}>
                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid size={{xs: 12, sm: "auto"}}>
                        <Toolbar>
                            <IconButton onClick={refresh}>
                            <Refresh/>
                            </IconButton>
                            {_.filter(accountList, a => a.shortcut).map(a => {
                            return <Button key={a.accountId} style={{margin: 5}} variant="outlined" onClick={()=>setAccount(`${a.provider}:${a.accountId}`)}>{a.alias}</Button>
                            })} 
                            Auto{<Switch checked={switchChecked} onChange={switchChangeHandler} />}
                            <Button disabled={!isSupported} variant="outlined" style={{margin: 10}} onClick={()=>(released === false ? release() : request())}>
                                {released === false ? 'Unlock' : 'Lock'}
                            </Button>
                            {released === undefined || released === false && 
                            <IconButton>
                                <LockIcon />
                            </IconButton>}
                        </Toolbar>
                    </Grid>
                    <Grid size={{xs: 12, md: 3}} sx={{margin: '0 2em 0 2em'}}>
                        <Slider size="small" value={risk} min={0.5} max={2.0} step={null} 
                            onChange={updateRisk}
                            marks={[
                                {value: 0.5, label: 'r:0.50'},
                                {value: 1.0, label: 'r:1.00'},
                                {value: 1.5, label: 'r:1.50'},
                                {value: 2.0, label: 'r:2.00'},
                            ]} />
                    </Grid>
                </Grid>
            </AppBar>
            <Snackbar open={status.message != null} onClose={()=>{setStatusMessage(null)}} 
                autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert variant="filled" severity={status.severity}>{status.message}</Alert>
            </Snackbar>
            <Grid container justifyContent="flex-end" sx={{marginBottom: 2, width: '100%'}}>
                {symbol && <Grid justifyContent="flex-start" alignItems="center" size={2}>
                    <NavLink style={()=>{ return {color: '#fff', textDecoration: 'none'};}} to="/">
                        <ArrowBackIosIcon />
                    </NavLink>
                </Grid>}
                <Grid container justifyContent="flex-end" alignItems="center" size={10}>
                    <Version version={tradingConfiguration.version}/>
                </Grid>
            </Grid>
            <Grid container id="foo1" spacing={2} style={{marginBottom: 80}} sx={{width: '100%'}}>
            <Grid container spacing={3} alignItems="flex-end" size={12}>
            <Grid container alignItems="center" size={{ xs: 12, md: 6 }}> 
                <Grid size={{ xs: 10, sm: 6, md: 8 }}>
                    <FormControl variant="outlined" fullWidth>
                    <InputLabel shrink id="account-label">
                        Account
                    </InputLabel>
                    <Select 
                        labelId="account-label"
                        id="accountSelector" 
                        native
                        label="Account"
                        variant="outlined"
                        value={selectedAccount}
                        onChange={onChangeHandler} 
                        >
                        {/* <option key="none" value="" >Select account</option> */}
                    {accountList && accountList.map(account => {
                        return <option key={account.accountId} value={account.provider+':'+account.accountId}>{account.accountId} - {account.alias}</option>
                    })}
                    </Select>
                    </FormControl>
                </Grid>
                <Grid container justifyContent="flex-start" size={{ xs: 2, sm: "auto"}}>
                    <Grid size={{ xs: 6, sm: "auto" }}>
                        <IconButton href={`${apiURLPrefix}/admin/trader/register`}>
                            <AddCircle color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid size={{ xs: 6, sm: "auto" }}>
                        <IconButton onClick={() => {removeAccount(selectedAccount)}}>
                            <RemoveCircle color="primary" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item container xs justifyContent="flex-start"> */}
            {!symbol && (
                <>
            <Grid size={{ xs: 12, sm: 6 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        id="from"
                        label="From"
                        // type="date"
                        // variant="outlined"
                        value={dayjs(from)}
                        className={classes.textField}
                        onChange={(newValue) => {
                            setFrom(dayjs(newValue));
                        }} 
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        style={{margin: 0}}
                        // slotProps={{textfield: { variant: 'outlined' }}}
                    />
                    <Button variant="outlined" style={{margin: 10}} onClick={()=>{setFrom(dayjs()); setTo(dayjs());}}>Today</Button>
                    <DatePicker
                        id="to"
                        label="To"
                        // type="date"
                        // variant="outlined"
                        value={to}
                        // value={to.format('YYYY-MM-DD')}
                        className={classes.textField}
                        onChange={(newValue) => {
                            setTo(dayjs(newValue));
                        }} 
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{margin: 0}}
                        slotProps={{textfield: { variant: 'outlined' }}}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid size={12}>
                <Button variant="outlined" style={{margin: 10}} onClick={()=>{shutdown(selectedAccount)}}>Shutdown</Button>
            </Grid>
            </>
            )}
            </Grid>
            {!symbol && (
            <Grid size={12}>
                <Box>Summary</Box>
                <Box>
                    <TradeSummary summary={tradeSummary} />
                </Box>
            </Grid>
            )}
            <Grid size={12}>
                <Positions tradingConfiguration={tradingConfiguration} wsHandler={webSocketHandler} 
                    setLoading={setLoading} 
                    setStatusMessage={setStatusMessage} refresh={refresh} 
                    selectedAccount={selectedAccount} 
                    setPositionData={setPositionData}
                    updatePosition={updatePosition}
                    positionChangeTime={positionChangeTime}
                    liveData={liveData} />
            </Grid>
            <Grid style={{marginBottom: '40px'}} size={12}>
            {!symbol ?
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant="body1">Alerts (24hrs)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Grid item xs={12} container> */}
                    <Grid className={classes.tile} size={12}>
                        <Alerts wsHandler={webSocketHandler} authorized={authorized} setStatusMessage={setStatusMessage} selectedAccount={selectedAccount} 
                            slackWebhookUrl={tradingConfiguration.slackWebhookUrl}
                            riskAmount={tradeSummary ? tradeSummary.currentBalance*risk/100 : 0}
                            riskPercentage={risk}
                            from={from} to={to} 
                            updatePosition={updatePosition} 
                            positionData={positionData} 
                            liveData={liveData} />
                    </Grid>
                    {/* <Grid className={classes.tile} item xs={12} md={4}>
                        <Accordion expanded={true}>
                            <AccordionSummary>Symbols</AccordionSummary>
                            <AccordionDetails>
                                <Symbols wsHandler={webSocketHandler} setStatusMessage={setStatusMessage} 
                                    selectedAccount={selectedAccount} 
                                    updatePosition={updatePosition} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    </Grid> */}
                </AccordionDetails>
            </Accordion> :
            <Alerts wsHandler={webSocketHandler} authorized={authorized} setStatusMessage={setStatusMessage} selectedAccount={selectedAccount} 
                slackWebhookUrl={tradingConfiguration.slackWebhookUrl}
                riskAmount={tradeSummary ? tradeSummary.currentBalance*risk/100 : 0}
                riskPercentage={risk}
                from={from} to={to} 
                updatePosition={updatePosition} 
                positionData={positionData} 
                liveData={liveData} />
            }
            {!symbol && (
                <>
            <Accordion>
                <AccordionSummary>
                    <Typography variant="body1">Performance By Symbol</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PerformanceBySymbol filter={[symbolFilter, setSymbolFilter]} trades={tradeSummary ? tradeSummary.trades : []} />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <Typography variant="body1">Daily Chart</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} justifyContent='space-around'>
                    <Grid
                        size={{
                            md: 6,
                            sm: "grow",
                            xs: 12
                        }}>
                    <DailyWinLossChart trades={tradeSummary ? tradeSummary.trades : []} />
                    </Grid>
                    <Grid
                        size={{
                            md: 6,
                            sm: "grow",
                            xs: 12
                        }}>
                    <DailyProfitLossChart trades={tradeSummary ? tradeSummary.trades : []} />
                    </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <Typography variant="body1">Trades</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TradeList symbolFilter={symbolFilter} trades={tradeSummary ? tradeSummary.trades : []} />
                </AccordionDetails>
            </Accordion>
            </>
            )}
            </Grid>
            </Grid>
            </main>
            </div>
        </StyledPageVisibility>
    );

}
