import React from 'react';
import { Container, Box } from '@mui/material';
import Dashboard from './Dashboard';
// import { ThemeProvider } from '@mui/styles';
// import { createTheme } from '@mui/material/styles';

export default function App() {
  // const theme = createTheme();
  return (
    // <ThemeProvider theme={theme}>
    // </ThemeProvider>
    <Container maxWidth="xxl">
      <Box my={6}>
        <Dashboard/>
      </Box>
    </Container>
  );
}
